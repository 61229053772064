import styled from 'styled-components';

export const StyledLoadingPage = styled.div`
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background: ${({ theme }) => theme.body};

	/* HTML: <div class="in-site-loader"></div> */
	.in-site-loader {
		width: 50px;
		aspect-ratio: 1;
		border: 2px solid ${(props) => props.theme.ctaButton};
		box-sizing: border-box;
		border-radius: 50%;
		display: grid;
		animation: l11 1.5s infinite linear;
		transform-origin: 50% 80%;
	}
	.in-site-loader:before,
	.in-site-loader:after {
		content: '';
		grid-area: 1/1;
		border: inherit;
		border-radius: 50%;
		animation: inherit;
		animation-duration: 1s;
		transform-origin: inherit;
	}
	.in-site-loader:after {
		--s: -1;
	}
	@keyframes l11 {
		100% {
			transform: rotate(calc(var(--s, 1) * 1turn));
		}
	}
`;

import React from "react";
import { StyledFooter } from "./styles";
import { ContactForm, Container } from "components";
import synkroLogo from "assets/synkro-logo.svg";
import { IoLogoFacebook } from "react-icons/io";
import { FaSquareInstagram } from "react-icons/fa6";
import { scrollTo } from "helpers";

const Footer = () => {
	const currentYear = new Date().getFullYear();

	return (
		<StyledFooter id="contact">
			<div className="contact-area">
				<ContactForm />
			</div>
			<Container>
				<div className="footer-container">
					<div className="footer-logo" onClick={() => scrollTo("top")}>
						<img src={synkroLogo} alt="Synkro Logo" />
					</div>
					<div className="footer-content">
						<div className="footer-additional">
							<p>
								<a href={"tel:+27751062669"}>+27 75 106 2669</a> |{" "}
								<a href={"mailto:info@synkrohealth.co.za"}>
									info@synkrohealth.co.za
								</a>
							</p>
							<p>Mon -Fri: 8am-5pm | Sat: 8am-2pm</p>
							<p className="footer-address">
								Unit 2 Oewerpark, Rokewood Ave, Die Boord, Stellenbosch (Inside
								Winelands Dentistry) <br />
								<span className="highlight">Parking available</span>
							</p>
						</div>
						<div className="footer-socials">
							<a
								href="https://www.facebook.com/synkrohealth"
								target="_blank"
								className="footer-social-link"
							>
								<IoLogoFacebook />
							</a>
							<a
								href="https://www.instagram.com/synkro.health"
								target="_blank"
								className="footer-social-link"
							>
								<FaSquareInstagram />
							</a>
							<p>{currentYear} © Synkro Health</p>
						</div>
					</div>
				</div>
			</Container>
			<div className="footer-credits">
				<p>
					Powered by{" "}
					<a href="https://www.zenithdigital.co.za" target="_blank">
						Zenith Digital Media
					</a>
				</p>
			</div>
		</StyledFooter>
	);
};

export default Footer;

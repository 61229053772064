import React, { useRef, useState } from "react";
import { StyledContactForm } from "./styles";
import { Button, Container } from "components";
import { toast } from "react-toastify";
import emailjs from "@emailjs/browser";
import contactConfig from "./contactConfig";
import { AiOutlineSync } from "react-icons/ai";

const ContactForm = () => {
	const formRef = useRef();

	const [emailSent, setEmailSent] = useState("");

	const [formData, setFormData] = useState({
		user_name: "",
		user_phone: "",
		user_email: "",
		message: "",
	});
	const [errors, setErrors] = useState({});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });

		handleBlur(e);
	};

	const validateField = (name, value, validations) => {
		for (const validation of validations) {
			if (validation.required && !value) {
				return validation.message;
			}
			if (validation.minLength && value.length < validation.minLength) {
				return validation.message;
			}
			if (validation.pattern && !validation.pattern.test(value)) {
				return validation.message;
			}
		}
		return "";
	};

	const handleBlur = (e) => {
		const { name, value } = e.target;
		const fieldConfig = Object.values(contactConfig)
			.flat()
			.find((field) => field.name === name);
		const errorMessage = validateField(name, value, fieldConfig.validations);
		setErrors({ ...errors, [name]: errorMessage });
	};

	const renderField = (field) => {
		const returnField = () => {
			switch (field.type) {
				case "submit":
					return (
						<Button
							text={updateButtonLabel()}
							type={"submit"}
							className={"contact-submit"}
						/>
					);
				case "textarea":
					return (
						<textarea
							name={field.name}
							placeholder={field.placeholder}
							value={formData[field.name]}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					);
				default:
					return (
						<input
							type={field.type}
							name={field.name}
							placeholder={field.placeholder}
							value={formData[field.name]}
							pattern={field.pattern || undefined}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					);
			}
		};

		return (
			<fieldset key={field.name}>
				<label htmlFor={field.name}>{field.label}</label>
				{returnField()}
				{errors[field.name] && (
					<p className={`${field.name}-error contact-field-error`}>
						{errors[field.name]}
					</p>
				)}
			</fieldset>
		);
	};

	const renderRows = () => {
		return Object.entries(contactConfig).map(
			([rowKey, rowConfig], rowIndex) => (
				<div
					key={`${rowKey}-row-index-${rowIndex + 1}}`}
					className={`row-${rowIndex + 1}`}
				>
					{rowConfig.map(renderField)}
				</div>
			)
		);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setEmailSent("sending");
		emailjs
			.sendForm("service_e2tpmh9", "template_pkd1g4p", formRef.current, {
				publicKey: "Un7P73Jw-MSnSoziY",
			})
			.then(
				() => {
					setEmailSent("success");
					toast.success("Message sent successfully!", {
						position: "bottom-center",
						autoClose: 3500,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: false,
						draggable: false,
						theme: "colored",
					});
				},
				(error) => {
					setEmailSent("error");
					toast.error("Error sending message, please try again.", {
						position: "bottom-center",
						autoClose: 3500,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: false,
						draggable: false,
						theme: "light",
					});
				}
			);
	};

	const updateButtonLabel = () => {
		switch (emailSent) {
			case "sending":
				return <AiOutlineSync size={24} className="spinner sync-icon" />;
			default:
				return "Send Message";
		}
	};

	return (
		<StyledContactForm>
			<Container className="contact-container">
				<div
					className="map-container"
					style={{
						maxWidth: "100%",
						overflow: "hidden",
						width: "100%",
						maxHeight: "500px",
					}}
				>
					<div
						id="my-map-canvas"
						style={{ height: "100%", width: "100%", maxWwidth: "100%" }}
					>
						<iframe
							style={{ height: "100%", width: "100%", border: "0" }}
							frameBorder="0"
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d902.3753879241589!2d18.849586372402413!3d-33.94299408717774!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1dcdb3cdc1da98cb%3A0x13c9f380ce31ee0d!2sSynkro%20Health!5e0!3m2!1sen!2sza!4v1724677674354!5m2!1sen!2sza"
						></iframe>
					</div>
				</div>
				<section className="contact-form-container">
					<h3 className="contact-form-heading">Feel free to contact us</h3>
					<form className="contact-form" ref={formRef} onSubmit={handleSubmit}>
						{renderRows()}
					</form>
				</section>
			</Container>
		</StyledContactForm>
	);
};

export default ContactForm;

import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import App from './App.jsx';
import { ThemeProvider } from 'styled-components';
import { light } from './themes';
import GlobalStyle from './GlobalStyles';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { router } from './router';

ReactDOM.createRoot(document.getElementById('synkro')).render(
	<React.StrictMode>
		<ThemeProvider theme={light}>
			<GlobalStyle />
			<ToastContainer />
			<RouterProvider router={router}>
				<App />
			</RouterProvider>
		</ThemeProvider>
	</React.StrictMode>
);

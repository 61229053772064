import React from 'react';
import { StyledLoadingPage } from './styles';

const LoadingPage = () => {
	return (
		<StyledLoadingPage>
			<div className='in-site-loader'></div>
		</StyledLoadingPage>
	);
};

export default LoadingPage;

export const light = {
	body: "#FFF6EB",

	ctaButton: "#5a7050",
	ctaButtonText: "#fbf6e6",
	ctaButtonHover: "#2d3828",

	aboutUsText: "#17301C",
	aboutUsHeading: "#374E2D",
	aboutBoxShadow: "0px 9.284px 34.233px 0px rgba(0, 28, 85, 0.1)",

	servicesHeading: "#374E2D",
	servicesText: "#17301C",

	contactInputBorder: "#374E2D",
	contactInputBackground: "#fff6eb",
	contactInputLabel: "#374E2D",
	contactHeader: "#374E2D",
	contactInputText: "#2d3828",

	serviceBlockBackground: "#a6e1fa",
	serviceBlockBoxShadow: "0px 8px 29.5px 0px rgba(0, 28, 85, 0.1)",

	footerText: "#43543C",
	footerSocialLink: "#43543C",
	footerMainColor: "#43543C",

	backToTopBackground: "#5a7050",
	backToTopBackgroundHover: "#2d3828",
	backToTopIcon: "#fff",
	backToTopBoxShadow: "0px 8px 29.5px 0px rgba(0, 28, 85, 0.1)",

	toastSuccessBackground: "#374E2D",
	toastSuccessText: "#fff",
	toastSuccessProgressBar: "#88a87a",
};

export default [
	{
		id: 1,
		link: "/#",
		text: "Home",
	},
	{
		id: 2,
		link: "/services",
		text: "What We Do",
	},
	{
		id: 3,
		link: "/services#who-we-are",
		text: "Who We Are",
	},
	{
		id: 4,
		link: "/#testimonials",
		text: "Testimonials",
	},
	{
		id: 5,
		link: "/#contact",
		text: "Contact",
	},
];

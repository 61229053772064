import styled from 'styled-components';

export const StyledPriceBubble = styled.div`
	.service-bubble {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;

		border-radius: 50%;
		width: 12rem;
		height: 12rem;
		animation: ${(props) => props.animation} 2.5s infinite;
		position: relative;

		@media screen and (min-width: 768px) {
			width: 12rem;
			height: 12rem;
		}

		.bubble-heading {
			display: flex;
			flex-direction: column;
			transition: all 0.5s ease-in-out;
			text-align: center;
			justify-content: center;
			align-items: center;
		}

		.title {
			font-size: 17px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			text-transform: capitalize;
			width: 90%;
		}
	}
`;

import { Suspense, lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import LoadingPage from './components/loadingPage';
import App from './App';

const Home = lazy(() => import('./pages/home/Home'));
const ServicesPage = lazy(() => import('./pages/services'));
const NoPage = lazy(() => import('./pages/noPage'));

const withSuspense = (LazyComponent) => {
	return (props) => (
		<Suspense fallback={<LoadingPage />}>
			<LazyComponent {...props} />
		</Suspense>
	);
};

const LazyHome = withSuspense(Home);
const LazyServicesPage = withSuspense(ServicesPage);
const LazyNoPage = withSuspense(NoPage);

export const router = createBrowserRouter([
	{
		path: '/',
		element: <App />,
		children: [
			{
				path: '/',
				element: <LazyHome />
			},
			{
				path: 'services',
				element: <LazyServicesPage />
			},
			{
				path: '*',
				element: <LazyNoPage />
			}
		]
	}
]);

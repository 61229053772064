import React, { useEffect, useState } from "react";
import { StyledNavigation, StyledNavigationContainer } from "./styles";
import navigationConfig from "configs/navigationConfig";
import { Button, Container } from "components";
import synkroMobileNavLogo from "assets/synkro-mobile-nav-logo.svg";
import { HiMenuAlt2 } from "react-icons/hi";
import { RxCross2 } from "react-icons/rx";
import { Link } from "react-router-dom";
import synkroNavLogo from "assets/synkro-nav-logo.svg";
import { booking } from "../../helpers";

const Navigation = () => {
	const [menuOpen, setMenuOpen] = useState(false);

	useEffect(() => {
		window.addEventListener("scroll", function () {
			setMenuOpen(false);
		});

		return () => {
			window.removeEventListener("scroll", function () {
				setMenuOpen(false);
			});
		};
	}, []);

	return (
		<StyledNavigationContainer>
			<StyledNavigation className="navigation">
				<Container className={"nav-container"}>
					<div className="navigation-container">
						<Link to="/" className="navigation-logo">
							<img src={synkroNavLogo} alt="Synkro Logo" className={"logo"} />
						</Link>
						<Link to="/" className="mobile-nav-logo">
							<img src={synkroNavLogo} alt="Synkro Logo" className={"logo"} />
						</Link>
						<div className="navigation-links">
							{navigationConfig.map((navLink) => (
								<Link
									className="navigation-link"
									key={navLink.id}
									to={navLink.link}
								>
									{navLink.text}
								</Link>
							))}
						</div>
						<div className="navigation-button">
							<Button text="Book Now" onClick={booking} />
						</div>
						<HiMenuAlt2
							size={36}
							color="#fff"
							className="menu-btn menu-button"
							onClick={() => {
								setMenuOpen(true);
							}}
						/>
						<div
							className={`mobile-nav-container ${menuOpen ? "open" : "closed"}`}
						>
							<RxCross2
								size={36}
								color="#fff"
								className="menu-button menu-button-close"
								onClick={() => {
									setMenuOpen(false);
								}}
							/>
							<Link
								to="/"
								className="mobile-navigation-logo"
								onClick={() => {
									setMenuOpen(false);
								}}
							>
								<img
									className="mobile-panel-logo"
									src={synkroMobileNavLogo}
									alt="Synkro Logo"
								/>
							</Link>
							<div className="mobile-nav-links">
								{navigationConfig.map((navLink) => (
									<Link
										className="mobile-nav-link"
										key={navLink.id}
										to={navLink.link}
										onClick={() => {
											setMenuOpen(false);
										}}
									>
										{navLink.text}
									</Link>
								))}
							</div>
							<div className="mobile-nav-button">
								<Button text="Book Now" onClick={booking} />
							</div>
						</div>
					</div>
				</Container>
			</StyledNavigation>
		</StyledNavigationContainer>
	);
};

export default Navigation;

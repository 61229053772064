import styled from "styled-components";

export const StyledServiceBlock = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	border-radius: 2px;
	overflow: hidden;
	text-align: center;

	.image {
		min-height: 308px;

		background-image: ${({ $bgimage }) => `url(${$bgimage})`};
		background-repeat: no-repeat;
		background-position: ${({ $imagePosition }) => `${$imagePosition}`} center;
		background-size: cover;
	}

	.info {
		padding: 20px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 25px;

		color: #3f3f3f;
		font-style: normal;
		font-weight: 400;
		line-height: normal;

		h1 {
			width: 100%;
			font-family: Antic Slab !important;
			font-size: 28px;
			text-transform: capitalize;
		}

		p {
			font-size: 14px;
		}

		.book-now-btn {
			width: 100%;
		}
	}

	@media (min-width: 768px) {
		text-align: left;
		.info {
			padding: 35px 60px;
			gap: 40px;
			align-items: flex-start;

			h1 {
				font-size: 36px;
			}

			p {
				font-size: 18px;
			}
		}
	}

	@media (min-width: 1280px) {
		flex-direction: row;

		&:nth-child(2n) {
			flex-direction: row-reverse;
			text-align: right;

			.info {
				align-items: flex-end;
			}
		}

		.image,
		.info {
			width: 50%;
		}
	}
`;

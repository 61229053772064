const contactConfig = {
	row1: [
		{
			label: 'Name',
			type: 'text',
			name: 'user_name',
			placeholder: 'Jane Doe',
			validations: [
				{
					required: true,
					message: 'Name is required'
				},
				{
					minLength: 2,
					message: 'Name must be at least 2 characters'
				}
			]
		},
		{
			label: 'Phone Number',
			type: 'tel',
			name: 'user_phone',
			pattern: '[0-9]{10}',
			placeholder: '012 345 6789',
			validations: [
				{
					required: true,
					message: 'Phone number is required'
				}
			]
		}
	],
	row2: [
		{
			label: 'Email',
			type: 'email',
			name: 'user_email',
			placeholder: 'jane.doe@email.com',
			validations: [
				{
					required: true,
					message: 'Email is required'
				}
			]
		}
	],
	row3: [
		{
			label: 'Message',
			type: 'textarea',
			name: 'message',
			placeholder: 'Hi there, I require your services...',
			validations: [
				{
					required: true,
					message: 'Message is required'
				}
			]
		}
	],
	row4: [
		{
			type: 'submit',
			name: 'submit'
		}
	]
};

export default contactConfig;

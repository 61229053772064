import styled from 'styled-components';
import hero from 'assets/hero.webp';

export const StyledPricingSection = styled.section`
	width: 100%;
	height: 100%;
	padding: 25px 0;

	@media screen and (min-width: 761px) {
		padding: 50px 0;
	}

	background: #b6d1d9;

	.pricing-section-container {
		display: flex;
		flex-direction: column-reverse;
		justify-content: center;
		align-items: center;
		gap: 37.6px;
		width: 100%;
		height: 100%;

		border-radius: 2px;
		background: url(${hero}) lightgray 50% / cover no-repeat;
		position: relative;
		z-index: 0;

		padding: 25px 0;

		@media screen and (min-width: 768px) {
			flex-direction: row;
			gap: 4rem;
			padding: 25px;
		}

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(182, 209, 217, 0.75);
			z-index: 1;
		}
	}

	.section-title {
		font-size: 36px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		text-transform: capitalize;
		text-align: center;
		font-family: Antic Slab !important;
		color: ${({ theme }) => theme.servicesHeading};
	}

	.video-container {
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		z-index: 5;
		padding: 0 1rem;

		@media screen and (min-width: 768px) {
			padding: 0;
		}

		@media screen and (min-width: 960px) {
			width: 75%;
			height: 75%;
		}

		.ad-video {
			width: 100%;
			height: auto;
			border-radius: 2px;
		}
	}

	.pricing-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 3rem;
		position: relative;
		z-index: 5;
	}

	.price-bubble-container {
		display: flex;
		flex-direction: column;
		gap: 2rem;
		flex-wrap: wrap;
		justify-content: center;

		@media screen and (min-width: 1280px) {
			flex-wrap: nowrap;
			flex-direction: row;
			gap: 4rem;
			padding: 25px;
		}
	}
`;
